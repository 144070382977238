<template>
  <div>
    <v-row v-show="isEligibleForFreeTrial">
      <v-col cols="12">
        <v-alert
          border="left"
          type="info"
        >
          <p>
            Hello, as a new user you are eligible for a free trial that will last for {{ formatValidity(freeTrialPlan.validity_unit, freeTrialPlan.validity) }}. Click activate to start your free trial.
          </p>
          <v-btn
            color="success"
            class="ml-2"
            :loading="isActivatingFreeTrial"
            @click="activateFreeTrial"
          >
            <v-icon left>
              {{ icons.play }}
            </v-icon>
            Activate Free Trial
          </v-btn>
        </v-alert>
      </v-col>
    </v-row>

    <confirm-dialog
      :show-dialog="showRedirectDialog"
      title="Redirecting"
      message="You will be redirected shortly. If you're not redirected within 10 seconds, please click 'Redirect Now' button"
      agree-text="Redirect Now"
      :is-agree-button-loading="isRedirecting"
      @agree="redirectToRouter"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mdiPlay } from '@mdi/js'
import { mapGetters } from 'vuex'
import billingIntervalName from '@/mixins/billingIntervalName'
import routerLoginMixin from '@/mixins/routerLoginMixin'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'

export default {
  components: {
    ConfirmDialog,
  },
  mixins: [routerLoginMixin, billingIntervalName],
  props: {
    customer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isEligibleForFreeTrial: false,
      showRedirectDialog: false,
      isRedirecting: false,
      isActivatingFreeTrial: false,
      freeTrialPlan: {
        name: '',
        validity: '',
        validity_unit: '',
      },
      icons: {
        play: mdiPlay,
      },
    }
  },
  computed: { ...mapGetters(['userInfo']) },
  mounted() {
    this.checkFreeTrialEligibility()
  },
  methods: {
    checkFreeTrialEligibility() {
      axios.get('/customer/is-eligible-for-free-trial')
        .then(response => {
          if (response.data.eligible) {
            this.isEligibleForFreeTrial = true
            this.freeTrialPlan = {
              name: response.data.freeTrialPlan.name,
              validity: response.data.freeTrialPlan.validity,
              validity_unit: response.data.freeTrialPlan.validity_unit,
            }
          } else {
            this.isEligibleForFreeTrial = false
          }
        })
        .catch(error => {
          console.error(error)
          this.$toast.error(error.response.data.message)
        })
    },
    activateFreeTrial() {
      this.isActivatingFreeTrial = true
      axios.post('customer/activate-free-trial')
        .then(() => {
          this.showRedirectDialog = true
          this.loginToRouter(this.userInfo.username, this.customer.router_auth_password, this.userInfo.routerLoginLink)
        }).catch(error => {
          console.error(error)
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isActivatingFreeTrial = false
        })
    },
    redirectToRouter() {
      this.isRedirecting = true
      this.loginToRouter(this.userInfo.username, this.customer.router_auth_password, this.userInfo.routerLoginLink)
    },
  },
}
</script>
