<template>
  <div class="internet-plans px-6">
    <v-row>
      <v-col md="12">
        <h1 class="text-center">
          Select a bundle
        </h1>
      </v-col>
    </v-row>
    <v-expand-transition>
      <PendingPlanChangeAlert ref="pendingPlanChangeAlertRef" />
    </v-expand-transition>
    <v-expand-transition>
      <FreeTrialAlert :customer="customer" />
    </v-expand-transition>

    <v-row
      v-if="isInternetPlansLoading"
      class="mx-5"
    >
      <v-col
        v-for="index in 8"
        :key="index"
        md="3"
        sm="12"
        class="pa-0"
      >
        <internet-plan-shimmer />
      </v-col>
    </v-row>

    <div v-else>
      <template v-for="(plans, interval) in groupedPlans">
        <v-row
          v-if="plans.length > 0"
          :key="interval"
          class="mx-5"
        >
          <v-col
            cols="12"
            md="12"
          >
            <h2>{{ interval }} Plans</h2>
          </v-col>
          <v-col
            v-for="plan in plans"
            :key="plan.id"
            md="3"
            sm="12"
          >
            <plan-card
              :plan="plan"
              :selected-plan="selectedPlan"
              :customer="customer"
              :customer-plan-id="customerPlanId"
              @plan-selected="selectPlan"
              @plan-changed="onPlanChanged"
            />
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import InternetPlanShimmer from '@/components/partials/shimmers/InternetPlanShimmer.vue'
import billingIntervalName from '@/mixins/billingIntervalName'
import PlanCard from '@/components/partials/HotspotPlanCard.vue'
import PendingPlanChangeAlert from '@/components/partials/PendingPlanChangeAlert.vue'
import FreeTrialAlert from '@/components/partials/FreeTrialAlert.vue'

export default {
  components: {
    InternetPlanShimmer,
    PlanCard,
    PendingPlanChangeAlert,
    FreeTrialAlert,
  },
  mixins: [billingIntervalName],
  data() {
    return {
      isInternetPlansLoading: true,
      isCustomerCurrentPlanLoading: true,
      customerPlanId: null,
      customer: null,
      plans: [],
      selectedPlan: {},
      toBeSelectedPlan: null,
      menu: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Internet Plans',
          disabled: true,
          to: { name: 'internet-plans' },
        },
      ],
    }
  },
  computed: {
    groupedPlans() {
      return {
        Minute: this.plans.filter(plan => plan.validity_unit === this.validityEnum.MINUTE),
        Hourly: this.plans.filter(plan => plan.validity_unit === this.validityEnum.HOUR),
        Daily: this.plans.filter(plan => plan.validity_unit === this.validityEnum.DAY),
        Weekly: this.plans.filter(plan => plan.validity_unit === this.validityEnum.WEEK),
        Monthly: this.plans.filter(plan => plan.validity_unit === this.validityEnum.MONTH),
        Yearly: this.plans.filter(plan => plan.validity_unit === this.validityEnum.YEAR),
      }
    },
  },
  created() {
    this.fetchCustomerDetails()
    this.fetchInternetPlans()
  },
  methods: {
    fetchInternetPlans() {
      this.isInternetPlansLoading = true
      axios
        .get('customer/internet-plans')
        .then(response => {
          this.plans = response.data
        })
        .catch(error => {
          console.error('Error fetching internet plans:', error)
        })
        .finally(() => {
          this.isInternetPlansLoading = false
        })
    },
    fetchCustomerDetails() {
      axios
        .get('customer')
        .then(response => {
          this.customer = response.data.customer
        })
        .catch(error => {
          console.error('Something went wrong:', error)
        })
    },
    selectPlan(plan) {
      this.selectedPlan = plan
      this.menu = false

      const cards = document.querySelectorAll('.internet-plan-card')
      cards.forEach(card => {
        card.classList.remove('selected-card')
      })

      const selectedCard = document.querySelector(`.internet-plan-card[data-plan-id="${plan.id}"]`)
      if (selectedCard) {
        selectedCard.classList.add('selected-card')
      }
    },
    onPlanChanged() {
      this.$refs.pendingPlanChangeAlertRef.checkForPendingPlanChange()
    },
  },
}
</script>
